import { Heading, Paragraph, Spacer, Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { Link as RouterLink } from '../../vendor/next'
import { BRAND_CONFIG } from '../../config/brand-configurations'
import { getSearchQueryString } from '../find-home/filters/utils/search.utils'

import { getSEOLinks } from './landing.utils'

const LinkColumn = styled(Stack)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing['3x'],
  [theme.mediaQueries.mdUp]: {
    width: '25%',
    marginBottom: 'unset',
  },
}))
const Link = styled(RouterLink)(({ theme }) => ({ marginRight: theme.spacing['4x'] }))

export function ListOfSEOLinks() {
  const { t } = useTranslation('landing', { keyPrefix: 'list_of_seo_links' })
  const sitemapLinks = getSEOLinks({ t })
  const countryCode = BRAND_CONFIG.countryCode

  return (
    <>
      <Heading>{t('title', { context: countryCode })}</Heading>
      <Spacer size="8x" />
      <Stack direction={'row'} wrap="wrap">
        {sitemapLinks.map((column, index) => {
          return (
            <LinkColumn gap="3x" key={index}>
              {column.map(({ name, sharedHome, searchArea }, index) => {
                let link = '/find-home'
                if (sharedHome) {
                  link += getSearchQueryString({
                    filterValues: {
                      sharedHome: ['sharedHome'],
                      searchAreas: [searchArea],
                    },
                  })
                } else {
                  link += getSearchQueryString({
                    filterValues: {
                      homeTypes: ['apartment'],
                      searchAreas: [searchArea],
                    },
                  })
                }
                const text = sharedHome ? t('room', { name }) : t('apartment', { name })
                return (
                  <Link href={link} key={index}>
                    <Paragraph numberOfLines={1}>{text}</Paragraph>
                  </Link>
                )
              })}
            </LinkColumn>
          )
        })}
      </Stack>
    </>
  )
}
