import styled from '@emotion/styled'
import { Divider, Heading, Paragraph, Stack } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div(({ theme }) => ({
  padding: `${theme.spacing['8x']} ${theme.spacing['6x']}`,
  [theme.mediaQueries.mdUp]: {
    padding: `${theme.spacing['24x']} 10%`,
  },
}))

const Title = styled.h2(({ theme }) => ({
  ...theme.typography.display.xs,
}))

const Grid = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  rowGap: theme.spacing['6x'],
  [theme.mediaQueries.mdUp]: {
    gridTemplateColumns: '3fr 2fr',
  },
}))

type Row = {
  title: string
  items: string[]
}

export function ServiceBreakdownSection() {
  const { t } = useTranslation('landing', { keyPrefix: 'services_breakdown_section' })
  const rows: Row[] = t('rows', { returnObjects: true })

  return (
    <Wrapper>
      {/* TODO: Page width? */}
      <Stack gap="16x" style={{ maxWidth: 1200, margin: 'auto' }}>
        <Title>{t('title')}</Title>
        <Stack gap="6x" divider={<Divider />}>
          {/* Span to add top divider */}
          <span />
          {rows.map(({ title, items }, index) => {
            return (
              <Grid key={index}>
                <Heading size="sm" as="h3">
                  {title}
                </Heading>
                <Stack gap="4x">
                  {items.map((item, index) => (
                    <Paragraph key={index} size="xl">
                      {item}
                    </Paragraph>
                  ))}
                </Stack>
              </Grid>
            )
          })}
          {/* Span to add bottom divider */}
          <span />
        </Stack>
      </Stack>
    </Wrapper>
  )
}
